import { client } from "external/services/graphql";
import { GET_PLACE_DETAILS } from "external/services/graphql/queries/map.queries";
import { showNotification } from "helpers/utils";

const useGoogleFetchPlaceDetails = () => {
  const fetchPlaceDetailsFromMeraki = (place_id, onSuccess) => {
    const gMapSessionToken = sessionStorage.getItem("gMapSessionToken") || null;
    return client
      .getInstance()
      .query({
        query: GET_PLACE_DETAILS,
        variables: {
          placeId: place_id,
          sessionToken: gMapSessionToken,
        },
      })
      .then((res) => {
        if (res.data?.getPlaceDetails.status) {
          const placeData = res.data.getPlaceDetails.placeDetails;
          const { lat, lng } = placeData;
          onSuccess && onSuccess({ lat, lng });
          return res.data.getPlaceDetails.placeDetails;
        } else {
          showNotification("Place details retrieval failed", "error");
        }
      })
      .catch((err) => {
        console.error(err);
        showNotification("Place details retrieval failed", "error");
        return {};
      });
  };

  return fetchPlaceDetailsFromMeraki;
};

export default useGoogleFetchPlaceDetails;
