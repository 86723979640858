import React, { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Shimmer from "react-shimmer-effect";

import classNames from "classnames";
import cloneDeep from "external/helpers/deepClone";
import { bindActionCreators } from "redux";

import { constructSlug } from "external/helpers/constructSlug";
import analyticsPubSub, {
  ANALYTICS_EVENTS,
} from "external/services/analyticsPubSub/index";
import upsdkService from "external/services/upsdkService";
import { userLogin, userLogout } from "external/store/actions/auth";
import { likeItem, unlikeItem } from "external/store/actions/likes";
import { setLocationDialog } from "external/store/actions/ui";
import itemSelector from "external/store/selectors/itemSelector";
import likesSelector from "external/store/selectors/likesSelector";
import upsdkSelector from "external/store/selectors/upsdkSelector";
import configSelector from "external/store/selectors/configSelector";
import { BaseContext } from "../../context/BaseContext";
import CurrencyHelper from "external/helpers/currency.helper";
import { translateOptions } from "../../i18n";
import LazyImage from "../LazyImage";
import AppButton from "../button/index.component";
import Counter from "../counter/index.component";
import DietIndicator from "../diet-indicator/index.component";
import FavoriteIndicator from "../favorite-indicator/index.component";
import ItemBadge from "../item-badge/index.component";
import Typography from "../typography/index.component";
import {
  COLOR_KOURNIKOVA,
  COLOR_WHITE,
} from "../../constants/colors.constants";
import useRouteHook from "../../hooks/useRoute.hook";

import "./index.component.scss";

function ItemCard({
  isDisabled,
  upsdk,
  item,
  history,
  likes,
  authToken,
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  subLocality,
  selectedStore,
  secondaryColor,
  config,
  selectedFontFamily,
  likeItem,
  unlikeItem,
  setLocationDialog,
}) {
  const BaseConsumer = useContext(BaseContext);
  const [showMore, setShowMore] = useState(false);
  const [showFavLoding, setFavLoading] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const { historyPush } = useRouteHook();
  const { isMobileView, isQrModeEnabled, isQsrEnabled } = BaseConsumer;

  // variables
  const cartItems = cloneDeep(upsdkSelector.getCart({ upsdk }).items);
  const showItemLikeButton = itemSelector.showItemLikeButton({ config });
  const showFoodTypeIndicator = itemSelector.showFoodTypeIndicator({ config });
  const configImageSize = configSelector.getImagesSizeFromConfig({ config });
  const { t } = useTranslation();
  let cartItem, isLiked;
  if (item) {
    cartItem = cartItems?.find((itemOfCart) => itemOfCart?.id === item?.id);
    isLiked = likesSelector.isLiked({ likes, item_id: item.id });
  }

  useEffect(() => {
    let quantity = 0;
    if (!cartItem) {
      setItemCount(quantity);
    } else {
      cartItems.forEach((row) => {
        if (row.id === item.id) {
          quantity = quantity + row.quantity;
        }
      });
      setItemCount(quantity);
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem]);

  const handleClick = () => {
    const { id, name } = item;
    const itemSlug = constructSlug(name, item.raw_data.slug);
    setTimeout(() => {
      sessionStorage.setItem("scrolledSoFar", window.pageYOffset);
      historyPush(`/item-list/${id}/${itemSlug}`);
    }, 0);
  };

  const handleIncrementItemCount = () => {
    if (!isQrModeEnabled && !subLocality && !selectedStore) {
      setLocationDialog();
      return;
    }
    if (item.has_options) {
      BaseConsumer.showItemCustomization(item);
      return;
    }
    upsdkService.addToCart(item.raw_data);
  };

  const handleDecrementItemCount = () => {
    upsdkService.removeFromCart(item.raw_data);
  };

  const handleCustomizeClick = (event) => {
    event?.stopPropagation();
    handleIncrementItemCount();
  };

  const handleLikeItem = () => {
    if (isQrModeEnabled) return;

    if (!authToken) {
      BaseConsumer.showLoginDialog();
      return;
    }
    setFavLoading(true);
    setTimeout(() => {
      setFavLoading(false);
    }, 500);
    likeItem(item);
  };

  const handleUnlikeItem = () => {
    if (isQrModeEnabled) return;

    if (!authToken) {
      BaseConsumer.showLoginDialog();
      return;
    }
    setFavLoading(true);
    setTimeout(() => {
      setFavLoading(false);
    }, 500);
    unlikeItem(item);
  };

  const handleViewLessClick = (event) => {
    event.stopPropagation();
    setShowMore(!showMore);
  };

  const handleViewMoreClick = (event) => {
    if (!isMobileView) {
      event.stopPropagation();
      setShowMore(!showMore);
      const eventObj = {
        item_id: item.id,
      };
      analyticsPubSub.publish(ANALYTICS_EVENTS.VIEW_MORE_DESC, eventObj);
    }
  };

  if (item) {
    // Props
    const {
      name,
      diet_type,
      is_best_seller,
      thumbnail,
      likes,
      description,
      price,
      is_featured,
      has_options,
      raw_data,
      is_stock_available,
      image_blur_hash,
    } = item;

    const showItemPrice = price && price >= 0;

    const renderPricingComponent = () => {
      return (
        <div className="pricing-details-wrapper">
          <div className="price-wrapper">
            {showItemPrice || (raw_data && raw_data.price_descriptor) ? (
              <Typography
                variant="h2"
                weight="semiBold"
                fontColor={primaryTextColor}
                className="discounted-item-price"
              >
                {showItemPrice
                  ? CurrencyHelper.format(price)
                  : raw_data && raw_data.price_descriptor}
              </Typography>
            ) : null}
            {raw_data && raw_data.markup_price && is_stock_available ? (
              <Typography
                variant="h4"
                weight="regular"
                className="original-item-price"
                fontColor={secondaryTextColor}
              >
                {CurrencyHelper.format(raw_data.markup_price)}
              </Typography>
            ) : null}
          </div>
          {showItemPrice && raw_data && raw_data.price_descriptor ? (
            <div
              className="item-quantity"
              dangerouslySetInnerHTML={{
                __html: `${raw_data.price_descriptor}`,
              }}
              style={{
                color: secondaryTextColor,
                fontFamily: selectedFontFamily,
              }}
            />
          ) : null}
        </div>
      );
    };

    const renderRightActionComponent = () => {
      if (isQrModeEnabled ? (isQsrEnabled ? false : true) : false) {
        return (
          <Fragment>
            {has_options && is_stock_available && (
              <AppButton
                variant="contained"
                className="qr-code-customizable-button"
                buttonColor={primaryColor}
                onClickCallback={handleCustomizeClick}
              >
                <Typography
                  variant="h3"
                  weight="regular"
                  fontColor={COLOR_WHITE}
                >
                  {t("itemCard.customize")}
                </Typography>
              </AppButton>
            )}
          </Fragment>
        );
      }
      return (
        <div className="right-action">
          <div className="button-wrapper">
            {!is_stock_available ? (
              <AppButton
                variant="contained"
                className="out-of-stock-cta"
                isDisabled={isDisabled}
                buttonColor={COLOR_KOURNIKOVA}
                onClickCallback={() => {}}
              >
                <Typography
                  variant="h3"
                  weight="bold"
                  fontColor={primaryTextColor}
                >
                  {t("buttons.outOfStock")}
                </Typography>
              </AppButton>
            ) : itemCount ? (
              <AppButton
                variant="contained"
                className="add-to-cart-cta"
                buttonColor={COLOR_WHITE}
                onClickCallback={handleIncrementItemCount}
              >
                <Counter
                  itemCount={itemCount}
                  primaryColor={COLOR_WHITE}
                  countDecrementCallback={handleDecrementItemCount}
                  countIncrementCallback={handleIncrementItemCount}
                  fontColor={primaryColor}
                />
              </AppButton>
            ) : (
              <AppButton
                variant="contained"
                className="add-to-cart-cta"
                buttonColor={COLOR_WHITE}
                onClickCallback={handleIncrementItemCount}
              >
                <Typography variant="h2" weight="bold" fontColor={primaryColor}>
                  {t("buttons.add")}
                </Typography>
              </AppButton>
            )}
          </div>
          {has_options && is_stock_available && (
            <Typography
              className="cutomize-button"
              onClickCallback={handleCustomizeClick}
              variant="h4"
              weight="regular"
              fontColor={secondaryTextColor}
            >
              {t("itemCard.customize")}
            </Typography>
          )}
        </div>
      );
    };

    const renderCardFooter = (
      <div
        className="card-footer"
        style={{
          background:
            description?.length > 100 && !showMore
              ? "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.9) 14%"
              : "#fff",
          padding: isMobileView
            ? "none"
            : description?.length > 100
              ? "49px 16px 16px 16px"
              : "10px 16px 16px 16px",
          height: isMobileView
            ? "none"
            : description?.length > 100
              ? "100px"
              : "52px",
        }}
      >
        {renderPricingComponent()}
        {showMore ? (
          <Typography
            variant="h3"
            weight={!isMobileView ? "semiBold" : "bold"}
            fontColor={primaryColor}
            className="show-description-btn"
            onClickCallback={handleViewLessClick}
            minWidth="70px"
          >
            {t("common.viewLess")}
          </Typography>
        ) : description?.length > 100 ? (
          <Typography
            variant="h3"
            weight={!isMobileView ? "semiBold" : "bold"}
            fontColor={primaryColor}
            onClickCallback={handleViewMoreClick}
            className="show-description-btn"
            minWidth="70px"
          >
            {t("common.viewMore")}
          </Typography>
        ) : null}

        {!isMobileView && renderRightActionComponent()}
      </div>
    );

    return (
      <div onClick={handleClick} className={classNames(["item-card-wrapper"])}>
        <div className="item-image-wrapper">
          <div className="image-add-container">
            <LazyImage
              size={isMobileView ? 180 : 320}
              alt={name}
              className="item-image"
              src={thumbnail}
              image_blur_hash={image_blur_hash}
              paddingTopProp={
                configImageSize === "large"
                  ? "77%"
                  : configImageSize === "medium"
                    ? "66%"
                    : "55%"
              }
              mobileHeight={
                configImageSize === "large"
                  ? "100px"
                  : configImageSize === "medium"
                    ? "85px"
                    : "70px"
              }
              itemCard={true}
              isMobileView={isMobileView}
              imageProps={{
                fetchpriority: "low",
                loading: "lazy",
                decoding: "async",
              }}
            />
            <div className="right-action-wrapper">
              {isMobileView && renderRightActionComponent()}
            </div>
          </div>
          {showItemLikeButton &&
            (isQrModeEnabled ? (isQsrEnabled ? true : false) : true) && (
              <FavoriteIndicator
                likes={likes}
                isFavorite={isLiked}
                isLoading={showFavLoding}
                primaryColor={primaryColor}
                primaryTextColor={primaryTextColor}
                config={config}
                isLoggedIn={authToken ? true : false}
                markFavoriteCallback={handleLikeItem}
                markUnfavoriteCallback={handleUnlikeItem}
              />
            )}

          <ItemBadge isFeatured={is_featured} isBestSeller={is_best_seller} />

          <div
            className="item-content"
            style={{
              height: showMore || isMobileView ? "100%" : "100px",
              borderRadius: showMore ? "10px" : "0px 0px 10px 10px",
              minHeight: "100px",
              bottom: showMore ? 0 : "100px",
            }}
          >
            <div className="card-title-wrapper">
              <div className="product-name-wrapper">
                {showFoodTypeIndicator && (
                  <DietIndicator
                    dietType={diet_type}
                    size={{ width: 16, height: 16 }}
                    className="diet-indicator"
                  />
                )}
                <Typography
                  variant="h2"
                  weight="bold"
                  className="product-name"
                  fontColor={primaryTextColor}
                >
                  {name}
                </Typography>
              </div>
            </div>
            <div
              className="item-description-wrapper"
              style={{
                overflowY: showMore ? "auto" : "hidden",
                height:
                  description?.length > 100
                    ? showMore
                      ? "63%"
                      : "80%"
                    : isMobileView
                      ? "initial"
                      : "100%",
              }}
            >
              <div
                className="item-description"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
                style={{
                  color: secondaryTextColor,
                  fontFamily: selectedFontFamily,
                  overflowY: showMore ? "auto" : "hidden",
                  height: "100%",
                  maxHeight: isMobileView ? "38px" : "52px",
                  lineHeight: "18px",
                }}
              />
              {isMobileView && description?.length > 70 && (
                <Typography
                  variant="h3"
                  weight="bold"
                  fontColor={secondaryColor}
                  onClickCallback={handleViewMoreClick}
                  className="show-description-btn-mobile"
                  minWidth="70px"
                >
                  <span style={{ color: secondaryTextColor }}>...</span>{" "}
                  {t("common.viewMore")}
                </Typography>
              )}
            </div>

            {isMobileView && renderCardFooter}
          </div>
        </div>
        {!isMobileView && renderCardFooter}
      </div>
    );
  }

  return isMobileView ? (
    <div style={{ display: "flex", height: "120px", margin: 15 }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Shimmer>
          <div style={{ height: 16, width: 100, borderRadius: 16 }} />
        </Shimmer>
        <Shimmer>
          <div style={{ height: 12, width: 200, borderRadius: 16 }} />
        </Shimmer>
        <Shimmer>
          <div style={{ height: 12, width: 200, borderRadius: 16 }} />
        </Shimmer>
        <Shimmer>
          <div style={{ height: 16, width: 50, borderRadius: 16 }} />
        </Shimmer>
      </div>
      <Shimmer>
        <div style={{ width: "140px", height: "80px", borderRadius: 16 }} />
      </Shimmer>
    </div>
  ) : (
    <div className="item-placeholder-wrapper">
      <div>
        <div>
          <Shimmer>
            <div
              style={{
                minHeight: "160px",
                minWidth: "100%",
                display: "block",
                backgroundSize: "800px 100%",
              }}
            ></div>
          </Shimmer>
          <div>
            <Shimmer>
              <div
                className="item-title-placeholder"
                style={{
                  width: "50%",
                  height: 16,
                  margin: 15,
                  borderRadius: 16,
                }}
              />
            </Shimmer>
            <Shimmer>
              <div
                className="item-title-placeholder"
                style={{
                  width: "80%",
                  height: 12,
                  borderRadius: 16,
                  margin: 15,
                }}
              />
            </Shimmer>
            <Shimmer>
              <div
                className="item-title-placeholder"
                style={{
                  width: "80%",
                  height: 12,
                  borderRadius: 16,
                  margin: 15,
                }}
              />
            </Shimmer>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <Shimmer>
          <div
            style={{ width: "30%", height: 16, borderRadius: 16, margin: 15 }}
          ></div>
        </Shimmer>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    upsdk: state.upsdk,
    config: state.config,
    likes: state.likes.data,
    userData: state.auth.data,
    subLocality: state.ui.subLocality,
    selectedStore: upsdkSelector.getStore(state),
    cart: upsdkSelector.getCart(state),
    authToken: upsdkSelector.getAuthHeader(state),
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      likeItem: likeItem,
      unlikeItem: unlikeItem,
      userLogin: userLogin,
      userLogout: userLogout,
      setLocationDialog: setLocationDialog,
    },
    dispatch,
  );

export default withTranslation(
  ["translations"],
  translateOptions,
)(connect(mapStateToProps, mapDispatchToProps)(ItemCard));
