const initialState = {
  gMapKey: null,
};

const googleMapKey = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GOOGLE_MAP_API_KEY: {
      return { ...state, gMapKey: action.payload };
    }

    default: {
      return state;
    }
  }
};

export const UPDATE_GOOGLE_MAP_API_KEY = "UPDATE_GOOGLE_MAP_API_KEY";
export default googleMapKey;
