import { client } from "external/services/graphql";
import { SEARCH_AUTO_COMPLETE } from "external/services/graphql/queries/map.queries";
import { showNotification } from "helpers/utils";

const useGoogleAutoSuggestions = () => {
  const fetchAutoSuggestion = (
    searchText,
    countryCode = "+91",
    onSuccess,
    onLoading,
  ) => {
    onLoading && onLoading(true);
    const gMapSessionToken = sessionStorage.getItem("gMapSessionToken") || null;
    client
      .getInstance()
      .query({
        query: SEARCH_AUTO_COMPLETE,
        variables: {
          searchText,
          countryCode,
          sessionToken: gMapSessionToken,
        },
        errorPolicy: "all",
      })
      .then((res) => {
        if (res.data?.getPlacesSuggestions.status) {
          onSuccess && onSuccess(res.data.getPlacesSuggestions.predictions);

          if (res.data?.getPlacesSuggestions.token) {
            const token = res.data?.getPlacesSuggestions.token;
            sessionStorage.setItem("gMapSessionToken", token);
          }
        } else {
          showNotification("Auto-complete request failed", "error");
        }
      })
      .catch((err) => {
        console.error(err);
        showNotification("Auto-complete request failed", "error");
      })
      .finally(() => {
        onLoading && onLoading(false);
      });
  };

  return fetchAutoSuggestion;
};

export default useGoogleAutoSuggestions;
