import React, { lazy, Suspense, useEffect, useState } from "react";
import { getAllCustomRoutes } from "external/helpers/getFooterLinks";
import configSelector from "external/store/selectors/configSelector";
import getCurrentFallbackHandler from "helpers/shimmerRoutingHelper";
import CustomPage from "../views/custom-page/index.component";
import BaseLayout from "../common/base-layout/index.component";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import { ORDERING_TYPE_QR } from "constants/enums.constants";
import pageSelector from "external/store/selectors/pageSelector";
import { useSubscription } from "@apollo/client/react/hooks/useSubscription";
import { gql } from "@apollo/client";
import upsdkService from "external/services/upsdkService";
import Explore from "../views/explore/index.component";
import upsdkSelector from "external/store/selectors/upsdkSelector";
import { connect } from "react-redux";
import MerakiEditorListener from "common/meraki-editor-listener";

const PageNotFound = lazy(
  () =>
    import(
      "../views/page-not-found/index.component" /* webpackChunkName: "PageNotFound" */
    ),
);

const LandingScreen = lazy(
  () =>
    import(
      "../views/landing-screen/index.component" /* webpackChunkName: "LandingScreen" */
    ),
);

const ItemDetails = lazy(
  () =>
    import(
      "../views/item-details/index.component" /* webpackChunkName: "ItemDetails" */
    ),
);

const OrderSuccess = lazy(
  () =>
    import(
      "../views/order-success/index.component" /* webpackChunkName: "OrderSuccess" */
    ),
);

const StoreLocator = lazy(
  () =>
    import(
      "../views/store-locator/index.component" /* webpackChunkName: "StoreLocator" */
    ),
);

const Cart = lazy(
  () => import("../views/cart/index.component" /* webpackChunkName: "Cart" */),
);

const Checkout = lazy(
  () =>
    import(
      "../views/checkout/index.component" /* webpackChunkName: "Checkout" */
    ),
);

const Orders = lazy(
  () =>
    import("../views/orders/index.component" /* webpackChunkName: "Orders" */),
);

const Favorites = lazy(
  () =>
    import(
      "../views/favorites/index.component" /* webpackChunkName: "Favourites" */
    ),
);

const Wallet = lazy(
  () =>
    import("../views/wallet/index.component" /* webpackChunkName: "Wallet" */),
);

const Profile = lazy(
  () =>
    import(
      "../views/profile/index.component" /* webpackChunkName: "Profile" */
    ),
);

const Coupons = lazy(
  () =>
    import(
      "../views/coupons/index.component" /* webpackChunkName: "Coupons" */
    ),
);

const ReferAndEarn = lazy(
  () =>
    import(
      "../views/refer-and-earn/index.component" /* webpackChunkName: "ReferAndEarn" */
    ),
);

const ByRef = lazy(
  () =>
    import("../views/by-ref/index.component" /* webpackChunkName: "ByRef" */),
);

const PaymentRedirect = lazy(() => import("../views/pg/index.component"));

const NewAppRoutes = (props) => {
  // props
  const { config, orderingType } = props;

  const [currentFallback, setCurrentFallback] = useState(<div />);

  // variables
  const isQrModeEnabled = orderingType === ORDERING_TYPE_QR;
  const landingScreenConfig = pageSelector.getLandingPage({ config });
  const itemDetailPageConfig = pageSelector.getItemDetailPage({ config });
  const storeLocatorPageConfig = pageSelector.getStoreLocatorPage({ config });
  const cartPageConfig = pageSelector.getCartPage({ config });
  const orderHistoryPageConfig = pageSelector.getOrderHistoryPage({ config });
  const favouritePageConfig = pageSelector.getFavouritesPage({ config });
  const walletPageConfig = configSelector.getWalletConfig({ config });
  const profilePageConfig = pageSelector.getProfilePage({ config });
  const couponsPageConfig = pageSelector.getCouponsPage({ config });
  const referEarnPageConfig = pageSelector.getReferEarnPage({ config });
  const isReferralEnabled = configSelector.getReferralBool({ config });
  const menuPageConfig = pageSelector.getMenuPage({ config });
  const pageNotFoundConfig = pageSelector.getPageNotFoundPage({ config });

  const allRoutes = getAllCustomRoutes(
    configSelector.getCustomPages({ config }),
  );

  const privateRoutes = [
    "/past-orders",
    "/favourites",
    "/wallet",
    "/profile",
    "/coupons",
    "/refer-and-earn",
  ];

  const parameterObj = {
    getCurrentFallbackHandler,
    setCurrentFallback,
    allRoutes,
    privateRoutes,
  };

  const { data, loading } = useSubscription(gql`
    subscription test {
      getCartUpdates {
        items
      }
    }
  `);

  let rootRoute = "";
  const subPath = configSelector.getSubPath({ config });

  if (subPath) {
    rootRoute = "/" + subPath;
  }

  const menuRoute = landingScreenConfig?.enabled
    ? `${rootRoute}/menu/`
    : `${rootRoute}/`;

  const childRoutes = [
    {
      path: rootRoute || "/",
      element:
        !isQrModeEnabled && landingScreenConfig?.enabled ? (
          <LandingScreen data={data} loading={loading} />
        ) : null,
    },
    {
      path: "/",
      element: subPath ? <Navigate to={rootRoute} /> : null,
    },
    {
      path: `${rootRoute}/item-list/:id/:slug`,
      element: itemDetailPageConfig.enabled ? <ItemDetails /> : null,
    },
    {
      path: `${rootRoute}/order-success/:id`,
      element: <OrderSuccess />,
    },
    {
      path: `${rootRoute}/store-locator`,
      element:
        !isQrModeEnabled && storeLocatorPageConfig.enabled ? (
          <StoreLocator />
        ) : null,
    },
    ...allRoutes.map((page) => ({
      path: `${rootRoute}${page.path}`,
      element: <CustomPage />,
    })),
    {
      path: `${rootRoute}/cart`,
      element: cartPageConfig.enabled ? <Cart {...props} /> : null,
    },
    {
      path: `${rootRoute}/checkout`,
      element: cartPageConfig.enabled ? <Checkout {...props} /> : null,
    },
    {
      path: `${rootRoute}/past-orders`,
      element: orderHistoryPageConfig.enabled ? <Orders {...props} /> : null,
    },
    {
      path: `${rootRoute}/favourites`,
      element:
        !isQrModeEnabled && favouritePageConfig.enabled ? (
          <Favorites {...props} />
        ) : null,
    },
    {
      path: `${rootRoute}/wallet`,
      element:
        !isQrModeEnabled && walletPageConfig.enable ? (
          <Wallet {...props} />
        ) : null,
    },
    {
      path: `${rootRoute}/profile`,
      element:
        !isQrModeEnabled && profilePageConfig.enabled ? (
          <Profile {...props} />
        ) : null,
    },
    {
      path: `${rootRoute}/coupons`,
      element: couponsPageConfig.enabled ? <Coupons {...props} /> : null,
    },
    {
      path: `${rootRoute}/refer-and-earn`,
      element:
        !isQrModeEnabled && referEarnPageConfig.enabled && isReferralEnabled ? (
          <ReferAndEarn {...props} />
        ) : null,
    },
    {
      path: `${rootRoute}/by_ref`,
      element:
        referEarnPageConfig.enabled && isReferralEnabled ? (
          <ByRef {...props} />
        ) : null,
    },
    {
      path: `${rootRoute}/pg/:type?`,
      element: <PaymentRedirect {...props} />,
    },
    /* Keep menu routes in the bottom to make sure other routes are accessible and work fine */
    {
      path: `${menuRoute}`,
      element: menuPageConfig.enabled ? <Explore /> : null,
    },
    {
      path: `${menuRoute}:catSlug/:subCatSlug`,
      element: menuPageConfig.enabled ? <Explore /> : null,
    },
    {
      path: `${menuRoute}:catSlug`,
      element: menuPageConfig.enabled ? <Explore /> : null,
    },
    {
      path: `${rootRoute}/landing-page-demo/:variant`,
      element: menuPageConfig.enabled ? <LandingScreen /> : null,
    },
    {
      path: `*`,
      element: pageNotFoundConfig.enabled ? (
        <PageNotFound />
      ) : (
        <Navigate to={<menuRoute />} />
      ),
    },
  ].filter((route) => route.element);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <BaseLayout {...parameterObj}>
          <Suspense fallback={currentFallback}>
            <Outlet />
          </Suspense>
        </BaseLayout>
      ),
      children: childRoutes,
    },
  ]);

  useEffect(() => {
    if (!loading && data) {
      try {
        const cartItems = data.getCartUpdates.items;
        const parsedItems = JSON.parse(cartItems);
        if (parsedItems?.data) {
          upsdkService.setCart(parsedItems?.data);
        } else {
          upsdkService.clearCart();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [data, loading]);

  return (
    <RouterProvider router={router}>
      <MerakiEditorListener />
    </RouterProvider>
  );
};

function mapStateToProps(state) {
  return {
    config: state.config,
    userData: state.auth.data,
    authToken: upsdkSelector.getAuthHeader(state),
  };
}

export default connect(mapStateToProps, null)(NewAppRoutes);
